<script setup>
import {computed, ref} from "vue";
import {useAuthStore} from "@/store/AuthStore";
import {useMainStore} from "@/store/MainStore";
import {useAnnouncementStore} from "@/store/AnnouncementStore";

const authStore = useAuthStore();
const mainStore = useMainStore();
const announcementStore = useAnnouncementStore();

const isAuth = computed(() => authStore.jwt !== null);

const phoneShow = ref(false);

const props = defineProps({
  buttonText: {
    type: String,
    default() {
      return ""
    }
  },
  size: {
    type: String,
    default() {
      return "";
    }
  },
  color: {
    type: String,
    default() {
      return "";
    }
  },
  background: {
    type: String,
    default() {
      return ""
    }
  },
  imageType: {
    type: String,
    default() {
      return ""
    }
  },
  phone: {
    type: String,
    default() {
      return ""
    }
  },
  id: {
    type: Number,
    default() {
      return 0
    }
  },
  contacts: {
    type: Number,
    default() {
      return 0
    }
  }
});

const phoneShowTrigger = () => {
  if (isAuth.value) {
    phoneShow.value = !phoneShow.value;
    if (phoneShow.value) {
      announcementStore.setAnnouncementContacts({
        id: props.id,
        contacts: props.contacts
      })
    }
  } else {
    mainStore.popup = 'auth';
  }
}
</script>

<template>
  <button
      class="button button_mainButton"
      :class="{
        'background_green': props.color === 'green',
        'background_subBg': props.color === 'blue',
        'bugSize': props.size === 'big'
      }"
      @click="phoneShowTrigger"
  >
    <p
        v-if="phone.length > 0"
        class="phone-change"
    >
      <span :class="{'cover': phoneShow, 'uncover': !phoneShow}">
          {{ props.buttonText }}
      </span>
      <span :class="{'cover': !phoneShow, 'uncover': phoneShow}">
        {{ props.phone }}
      </span>
    </p>
    <p v-else>
      {{ props.buttonText }}
    </p>
    <span
        v-if="props.buttonText === 'Показать телефон'"
        class="image"
    >
      <svg width="18px" id="Phone-1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
           viewBox="0 0 32 32" style="enable-background:new 0 0 32 32;" xml:space="preserve">
        <g>
          <path d="M16,0C0.449,0,0,5.387,0,6.001v6C0,13.103,0.897,14,2,14h6c1.103,0,2-0.897,2-1.999V8h12v4.001C22,13.103,22.897,14,24,14
            h6c1.103,0,2-0.897,2-1.999v-6C32,5.387,31.551,0,16,0z M30,12l-6,0.001V7c0-0.552-0.448-1-1-1H9C8.448,6,8,6.448,8,7v5l-6,0.001
            L1.999,6.044C2.002,6.004,2.536,2,16,2c13.458,0,13.997,4.001,14,4.001V12z" fill="white"/>
          <path d="M27,28c0,1.103-0.897,2-2,2H7c-1.103,0-2-0.897-2-2V16H3v12c0,2.206,1.794,4,4,4h18c2.206,0,4-1.794,4-4V16h-2V28z" fill="white"/>
          <path d="M23,21c0-3.86-3.14-7-7-7s-7,3.14-7,7s3.14,7,7,7S23,24.86,23,21z M11,21c0-2.757,2.243-5,5-5s5,2.243,5,5s-2.243,5-5,5
            S11,23.757,11,21z" fill="white"/>
          <circle cx="16" cy="21" r="2" fill="white"/>
        </g>
      </svg>
    </span>
    <span
        v-if="props.buttonText === 'Показать Email'"
        class="image book"
    >
      <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
           width="25" height="25" viewBox="0 0 1280.000000 1280.000000"
           preserveAspectRatio="xMidYMid meet">
        <metadata>
          Created by potrace 1.15, written by Peter Selinger 2001-2017
        </metadata>
        <g transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)"
         fill="#000000" stroke="none">
          <path d="M8560 9654 c-19 -2 -98 -9 -175 -15 -77 -6 -203 -21 -281 -34 -436
            -74 -706 -194 -925 -414 -53 -53 -108 -112 -122 -131 l-27 -35 -26 35 c-104
            137 -325 310 -495 388 -490 226 -1392 266 -2489 111 -200 -28 -619 -101 -754
            -131 l-60 -13 -178 -255 c-143 -204 -183 -256 -206 -261 -15 -4 -196 -14 -402
            -23 -451 -20 -458 -20 -470 -49 -5 -12 -25 -272 -45 -577 -20 -305 -47 -715
            -60 -910 -14 -195 -49 -728 -80 -1185 -30 -456 -62 -927 -70 -1045 -8 -118
            -33 -485 -55 -815 -22 -330 -42 -629 -45 -665 -2 -36 -10 -139 -16 -230 l-11
            -165 58 -153 c33 -85 66 -157 74 -162 8 -4 308 12 665 36 1105 73 1393 92
            2250 149 457 30 976 65 1155 77 l325 21 150 -51 c278 -95 488 -143 710 -163
            291 -26 621 32 928 163 114 49 127 52 197 50 41 -1 1029 -61 2194 -133 1730
            -106 2122 -128 2131 -117 7 7 36 80 65 162 l53 148 -62 877 c-109 1547 -218
            3094 -277 3936 -40 581 -61 824 -70 834 -10 11 -83 17 -321 27 -169 7 -357 15
            -418 18 -60 3 -128 6 -151 6 l-40 0 -173 239 c-137 191 -177 241 -199 245
            -368 76 -891 154 -1283 192 -153 14 -878 28 -969 18z m840 -649 c312 -28 741
            -83 996 -126 l61 -11 7 -121 c3 -67 11 -214 16 -327 42 -887 70 -1490 75
            -1570 11 -209 17 -330 45 -940 16 -349 32 -668 35 -709 l6 -73 -33 4 c-18 3
            -143 21 -278 41 -496 73 -763 92 -1330 92 -469 0 -580 -6 -830 -46 -344 -54
            -645 -180 -887 -370 -35 -27 -67 -49 -73 -49 -6 0 -10 476 -12 1393 -4 2037
            -4 2004 10 2072 60 277 171 424 414 550 197 102 480 173 818 205 144 13 757 4
            960 -15z m-3620 -4 c147 -18 335 -59 447 -96 339 -113 536 -298 625 -587 l23
            -73 3 -1718 c2 -1385 0 -1717 -10 -1710 -7 4 -52 34 -100 66 -275 183 -636
            297 -1108 353 -185 21 -677 29 -915 15 -338 -21 -744 -63 -1119 -116 -94 -14
            -171 -23 -173 -21 -2 2 3 149 11 327 9 178 22 482 31 674 8 193 17 397 20 455
            13 268 16 347 30 665 8 187 24 536 35 775 11 239 22 516 26 615 4 99 10 189
            15 201 6 16 21 23 76 32 37 7 133 23 213 37 274 47 599 87 865 105 88 6 180
            12 205 14 97 8 710 -3 800 -13z m-2585 -668 c-3 -49 -14 -272 -25 -498 -11
            -225 -27 -540 -35 -700 -18 -344 -49 -980 -70 -1405 -16 -322 -21 -418 -31
            -595 l-7 -109 -150 -226 c-82 -124 -154 -229 -159 -235 -10 -9 -6 166 7 360 3
            39 12 196 20 350 8 154 24 447 35 650 11 204 29 541 40 750 11 209 27 506 35
            660 8 154 17 316 20 360 2 44 8 132 11 195 l7 115 151 207 c83 115 152 208
            154 208 2 0 1 -39 -3 -87z m7844 -136 l149 -208 6 -92 c3 -51 15 -252 26 -447
            11 -195 29 -512 40 -705 59 -1028 83 -1437 95 -1665 3 -52 10 -162 15 -245 5
            -82 10 -174 10 -204 l0 -54 -164 237 -164 236 -6 88 c-4 48 -18 303 -31 567
            -14 264 -39 732 -55 1040 -17 308 -39 731 -50 940 -21 405 -26 495 -36 645 -4
            67 -3 92 5 85 6 -6 77 -104 160 -218z m705 55 c3 -37 8 -105 11 -152 3 -47 21
            -328 40 -625 35 -534 47 -725 125 -1925 22 -338 47 -721 55 -850 8 -129 17
            -269 20 -310 3 -41 14 -214 25 -385 11 -170 22 -343 25 -384 l6 -73 -133 7
            c-73 4 -135 9 -137 11 -2 2 14 66 37 142 l42 137 -10 145 c-5 80 -50 793 -100
            1585 -50 792 -108 1725 -130 2074 -22 349 -40 644 -40 655 0 20 5 21 79 18
            l79 -3 6 -67z m-9259 -69 c-3 -43 -17 -271 -30 -508 -26 -434 -50 -849 -60
            -1005 -7 -112 -54 -902 -115 -1950 -16 -278 -32 -532 -34 -565 -3 -33 -8 -126
            -12 -206 l-7 -146 43 -127 c23 -70 40 -129 37 -132 -5 -5 -260 -24 -265 -20
            -1 2 25 441 58 977 33 536 76 1233 95 1549 19 316 37 609 40 650 3 41 23 368
            45 725 22 358 42 692 46 743 l6 92 80 0 79 0 -6 -77z m6715 -3513 c449 -21
            830 -62 1362 -146 l227 -36 120 -181 c66 -100 119 -182 118 -183 -1 -2 -268
            15 -592 36 -608 40 -1564 102 -2230 145 -209 13 -404 26 -433 29 -51 4 -54 3
            -110 -45 -31 -27 -95 -69 -142 -93 -84 -44 -218 -91 -228 -81 -3 3 16 37 42
            75 249 363 880 526 1866 480z m-3720 -10 c573 -33 914 -135 1134 -340 56 -51
            152 -183 138 -188 -16 -5 -164 56 -217 89 -28 17 -69 49 -93 71 l-43 39 -67
            -6 c-37 -3 -391 -28 -787 -55 -844 -58 -1125 -77 -1880 -130 -308 -21 -561
            -37 -563 -35 -4 3 213 342 232 363 15 15 148 39 516 92 628 90 1196 125 1630
            100z"
          />
        </g>
      </svg>
    </span>
  </button>
</template>

<style scoped lang="scss">
.bugSize {
  height: rem(55);
  @media (max-width: em(1024, 16)) {
    font-size: 1rem;
  }
  .book{
    path{
      fill: $color_bgLight;
    }
  }
}
.button_mainButton{
  position:relative;
  .image{
    left: 5%;
    position:absolute;
  }
}

.phone-change {
  span {
    transition: opacity 0.3s;
    position: absolute;
    top: 35%;
    left: 35%;

    &:last-child {
      left: 38%;
    }
  }
  .cover {
    opacity: 0;
  }
  .uncover {
    opacity: 1;
  }
}
</style>