<script setup>

</script>

<template>
  <header class="headerCartPage ">
    <div class="wrapper">
      <div class="arrow">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
          <path
              d="M0.292892 7.29289C-0.0976315 7.68342 -0.0976315 8.31658 0.292892 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8L8.07107 2.34315C8.46159 1.95262 8.46159 1.31946 8.07107 0.928932C7.68054 0.538408 7.04738 0.538408 6.65685 0.928932L0.292892 7.29289ZM16 7H0.999999V9H16V7Z"
              fill="black"/>
        </svg>
      </div>
      <div class="actions">
        <svg fill="#000000" height="25px" width="25px" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
             viewBox="0 0 612 612" xml:space="preserve">
          <g>
	<g>
		<g>
			<path d="M493.094,389.141c-20.171,0-39.092,5.416-55.434,14.829L230.247,302.949c0.036-1.192,0.092-2.384,0.092-3.586
				c0-1.202-0.054-2.394-0.092-3.587l195.8-95.366c19.249,14.537,42.629,22.45,67.046,22.45
				c61.442,0,111.429-49.988,111.429-111.432C604.523,49.987,554.535,0,493.094,0C438.247,0,391.82,40.257,383.131,93.398
				L166.79,198.766c-14.516-6.937-30.754-10.83-47.884-10.83c-61.442,0-111.429,49.988-111.429,111.429
				c0,61.444,49.988,111.429,111.429,111.429c17.134,0,33.369-3.894,47.884-10.83l214.98,104.704
				C383.935,564.217,433.028,612,493.094,612c61.442,0,111.429-49.987,111.429-111.429S554.536,389.141,493.094,389.141z
				 M416.773,106.113c2.734-39.918,36.256-71.187,76.321-71.187c42.185,0,76.503,34.319,76.503,76.503
				c0,42.185-34.318,76.506-76.503,76.506c-19.615,0-38.266-7.415-52.52-20.877c-15.465-14.609-23.983-34.364-23.983-55.63
				C416.591,109.665,416.654,107.874,416.773,106.113z M383.634,132.002c2.833,14.96,8.705,29.223,17.213,41.834L223.264,260.33
				c-5.424-14.454-13.774-27.478-24.319-38.375L383.634,132.002z M42.403,299.365c0-42.183,34.321-76.503,76.503-76.503
				c42.185,0,76.506,34.319,76.506,76.503s-34.321,76.503-76.506,76.503C76.724,375.867,42.403,341.548,42.403,299.365z
				 M386.5,468.122l-187.552-91.346c10.542-10.897,18.892-23.923,24.319-38.375l184.957,90.085
				C398.473,439.945,390.993,453.383,386.5,468.122z M493.094,577.074c-42.182,0-76.503-34.319-76.503-76.503
				s34.321-76.503,76.503-76.503c42.185,0,76.503,34.319,76.503,76.503S535.279,577.074,493.094,577.074z"/>
		</g>
	</g>
</g>
      </svg>
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" viewBox="0 0 18 16" fill="none">
          <path
              d="M17 5.57573C17 6.78282 16.525 7.94225 15.6766 8.79991C13.7239 10.7748 11.8299 12.8341 9.80424 14.7374C9.33992 15.1673 8.60336 15.1516 8.15904 14.7022L2.32301 8.79991C0.558998 7.01582 0.558998 4.13563 2.32301 2.35157C4.10435 0.549977 7.00635 0.549977 8.78768 2.35157L8.99984 2.5661L9.21184 2.35169C10.0659 1.48745 11.2291 1 12.4442 1C13.6594 1 14.8225 1.4874 15.6766 2.35157C16.525 3.20929 17 4.36865 17 5.57573Z"
              stroke="black" stroke-linejoin="round"/>
        </svg>
      </div>
    </div>
  </header>
</template>

<style scoped lang="scss">
.headerCartPage {

  padding: rem(12) 0;

  .wrapper {
    display: flex;
    justify-content: space-between;
  }

  .actions {
    display: flex;
    gap: rem(15);
  }

  svg{
    cursor: pointer;
  }

  @media (max-width: em(1024, 16)) {
    .actions {
      gap: calc(15rem / 16 + (25 - 15) * ((100vw - 390rem / 16) / (1920 - 390)))
    }
  }
}
</style>